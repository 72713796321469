<template>
  <div>
    <div class="zt" v-if="params && params.examineStatus === 1">
      <img src="@/assets/img/recruit/icon-shz.png" alt="" />
      您的企业认证正在审核中，请耐心等待……
    </div>
    <div class="zt" v-if="params && params.examineStatus === 2">
      <img src="@/assets/img/recruit/icon-ok.png" alt="" />
      认证通过
    </div>
    <div class="zt" v-if="params && params.examineStatus === 3">
      <img src="@/assets/img/recruit/icon-error.png" alt="" />
      {{params.examineRemark}}
    </div>
    <el-form :model="params" :rules="rules" ref="ruleForm" label-width="0">
    <div class="zl">
      <div class="gs">
        <span><i>*</i>企业logo</span>
        <el-form-item label="" prop="enterpriseLogo">
        <el-upload
          class="custom-upload"
          accept="image/*"
          :show-file-list="false"
          action="http://danlingzp.com/prod-api/system/oss/upload"
          list-type="picture-card"
          :disabled="disabledInput"
          :on-success="(v) => changeUpload(v, 'enterpriseLogo')"
        >
          <img v-if="params.enterpriseLogo" width="100%" height="100%" :src="params.enterpriseLogo" class="avatar qylogo">
          <i v-else class="el-icon-zp"></i>
        </el-upload>
        </el-form-item>
      </div>
      <div class="qy-item">
        <p><i>*</i>企业名称</p>
        <el-form-item label="" prop="enterpriseName">
        <el-input :disabled="disabledInput" v-model="params.enterpriseName" placeholder="请输入企业名称"></el-input>
        </el-form-item>
      </div>
      <div class="qy-item">
        <p><i>*</i>法人</p>
        <el-form-item label="" prop="legalPerson">
        <el-input :disabled="disabledInput" v-model="params.legalPerson" placeholder="请输入法人"></el-input>
        </el-form-item>
      </div>
      <div class="qy-item">
        <p><i>*</i>信用代码</p>
        <el-form-item label="" prop="creditCode">
        <el-input :disabled="disabledInput" v-model="params.creditCode" placeholder="请输入信用代码"></el-input>
        </el-form-item>
      </div>

      <div class="qy-item">
        <p><i>*</i>注册资本</p>
        <el-form-item label="" prop="registerCapital">
        <el-input style="width: 270px" :disabled="disabledInput" v-model="params.registerCapital" placeholder="请输入注册资本"></el-input>&nbsp;&nbsp;万元
        </el-form-item>
      </div>
      <div class="qy-item">
        <p><i>*</i>企业性质</p>
        <el-form-item label="" prop="enterpriseNature">
        <el-select :disabled="disabledInput" @change="(v) => selectChange(v,'company_nature', 'enterpriseNatureName')" v-model="params.enterpriseNature" placeholder="请选择职位类型">
            <el-option
              v-for="(item) in options.company_nature"
              :key="item.dictCode"
              :label="item.dictLabel"
              :value="item.dictCode"
            >
            </el-option>
        </el-select>
      </el-form-item>
      </div>
      <div class="qy-item">
        <p><i>*</i>成立时间</p>
        <el-form-item label="" prop="certificationTime">
        <el-date-picker :disabled="disabledInput" v-model="params.certificationTime" type="date" placeholder="选择日期">
        </el-date-picker>
        </el-form-item>
      </div>

      <div class="qy-item">
        <p><i>*</i>企业规模</p>
        <el-form-item label="" prop="enterpriseSize">
        <el-select :disabled="disabledInput" @change="(v) => selectChange(v,'enterprise_size', 'enterpriseSizeName')" v-model="params.enterpriseSize" placeholder="请选择职位类型">
            <el-option
              v-for="(item) in options.enterprise_size"
              :key="item.dictCode"
              :label="item.dictLabel"
              :value="item.dictCode"
            >
            </el-option>
        </el-select>
      </el-form-item>
      </div>
      <div class="qy-item">
        <p><i>*</i>公司联系人</p>
        <el-form-item label="" prop="legalName">
        <el-input :disabled="disabledInput" v-model="params.legalName" placeholder="请输入公司联系人"></el-input>
        </el-form-item>
      </div>
      <div class="qy-item">
        <p><i>*</i>公司联系方式</p>
        <el-form-item label="" prop="leaglTel">
        <el-input :disabled="disabledInput" maxlength="20" v-model="params.leaglTel" placeholder="请输入公司联系方式"></el-input>
        </el-form-item>
      </div>

      <div class="qy-item gzd">
        <p><i>*</i>工作地</p>
        <el-form-item label="" prop="provinCode">
        <el-select :disabled="disabledInput"  @change="changecity(2)" v-model="params.provinCode" placeholder="请选择">
          <el-option
            v-for="(item) in citys[1]"
            :key="item.dictCode"
            :label="item.dictValue"
            :value="item.dictCode"
          >
          </el-option>
        </el-select>
        </el-form-item>
        <el-form-item label="" prop="cityCode">
        <el-select :disabled="disabledInput"  @change="changecity(3)" v-model="params.cityCode" placeholder="请选择">
          <el-option
            v-for="(item) in citys[2]"
            :key="item.dictCode"
            :label="item.dictValue"
            :value="item.dictCode"
          >
          </el-option>
        </el-select>
        </el-form-item>
        <el-form-item label="" prop="areaCode">
        <el-select :disabled="disabledInput" @change="changecity(4)" v-model="params.areaCode" placeholder="请选择">
          <el-option
            v-for="(item) in citys[3]"
            :key="item.dictCode"
            :label="item.dictValue"
            :value="item.dictCode"
          >
          </el-option>
        </el-select>
        </el-form-item>
      </div>
      <div class="qy-item">
        <p><i>*</i>详细地址</p>
        <el-form-item label="" prop="address">
        <el-input :disabled="disabledInput" v-model="params.address " placeholder="请输入公司详细地址"></el-input>
        </el-form-item>
      </div>

      <div class="qy-item clear zwms" style="height: 16rem" >
        <p><i>*</i>企业简介</p>
        <el-form-item label="" prop="enterpriseRemarke ">
<!--          <el-input type="textarea" maxlength="500" :disabled="disabledInput" v-model="params.enterpriseRemarke" name="" id="" cols="30" rows="9"></el-input>-->
           <quill-editor :disabled="disabledInput" ref="editor"  style="height: 10rem" :options="editorOption" id="description" v-model="params.enterpriseRemarke"></quill-editor>
        </el-form-item>
      </div>

      <div class="qy-item clear">
        <p><i>*</i>营业执照</p>
        <el-form-item label="" prop="registerUrl">
        <el-upload
          class="custom-upload2"
          accept="image/*"
          :show-file-list="false"
          action="http://danlingzp.com/prod-api/system/oss/upload"
          list-type="picture-card"
          :disabled="disabledInput"
          :before-upload="beforeUpload"
          :on-progress="(event, file, fileList) => progress(event, file, fileList, 'registerUrl')"
          :on-success="(v) => changeUpload(v, 'registerUrl')"
        >
          <img v-if="params.registerUrl" width="100%" height="100%" :src="params.registerUrl" class="avatar">
          <i v-else class="el-icon-zp"></i>
        </el-upload>
        <el-progress v-if="percentage.registerUrl" :percentage="percentage.registerUrl"></el-progress>
        </el-form-item>
      </div>
      <div class="clear"></div>
      <div class="qy-item" v-for="(item,idx) in enterpriseEnvironment" :key="item">
        <p>{{idx === 0?'办公环境':''}}</p>
        <img v-if="item" width="100%" height="170" :src="item" class="avatar">
        <i v-if="!disabledInput" @click="removeImg(idx)" class="close el-icon-circle-close"></i>
      </div>
      <div class="qy-item" v-if="!disabledInput">
        <p>{{enterpriseEnvironment.length?'':'办公环境'}}</p>
        <el-upload
          class="custom-upload2"
          accept="image/*"
          :show-file-list="false"
          :before-upload="beforeUpload"
          action="http://danlingzp.com/prod-api/system/oss/upload"
          list-type="picture-card"
          :disabled="disabledInput"
          :on-progress="(event, file, fileList) => progress(event, file, fileList, 'enterpriseEnvironment')"
          :on-success="(v) => changeUpload2(v, 'enterpriseEnvironment')"
        >
          <i class="el-icon-zp"></i>
        </el-upload>
        <el-progress v-if="percentage.enterpriseEnvironment" :percentage="percentage.enterpriseEnvironment"></el-progress>
      </div>



      <div class="qy-item clear">
        <p>宣传视频</p>
        <el-upload
          class="custom-upload2"
          :show-file-list="false"
          action="http://danlingzp.com/prod-api/system/oss/upload"
          list-type="picture-card"
          accept="video/*"
          :before-upload="beforeUpload"
          :on-progress="(event, file, fileList) => progress(event, file, fileList, 'enterpriseVideo')"
          :disabled="disabledInput"
          :on-success="(v) => changeUpload(v, 'enterpriseVideo')"
        >
          <video v-if="params.enterpriseVideo" width="100%" height="100%" :src="params.enterpriseVideo" class="avatar"></video>
          <i v-else class="el-icon-zp"></i>
        </el-upload>
        <el-progress v-if="percentage.enterpriseVideo" :percentage="percentage.enterpriseVideo"></el-progress>
      </div>
      <div class="qy-item clear zwms">
        <el-button v-show="!disabledInput" @click="submitForm('ruleForm')" class="btn" type="primary">提交</el-button>
      </div>
    </div>
    </el-form>
  </div>
</template>
<script>
import {getDictType, cityCode, enterpriseAdd, getenterprise, enterpriseEdit} from '@/api/zp'
import event from '@/utils/event';
export default {
  name: 'CenterIndex',
  data() {
    return {
      citys:[
        [],
        [],
        [],
        []
      ],
      percentage:{},
      editorOption: {
        placeholder: "请输入企业简介",
        enable: true,
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          ]
        }
      },
      enterpriseEnvironment:[],
      options:{},
      params: {
        enterpriseRemarke:'',
        enterpriseLogo: '',
        enterpriseName:'',
        legalPerson:'',
        creditCode:'',
        registerCapital:'',
        enterpriseNature:'',
        certificationTime:'',
        enterpriseSize:'',
        legalName:'',
        leaglTel:'',
        provinCode:'',
        cityCode:'',
        areaCode:'',
        address:'',
        registerUrl:'',
        enterpriseEnvironment:'',
        enterpriseVideo:''
      },
      rules: {
        enterpriseLogo: [{ required: true, message: '请上传企业logo', trigger: 'change' }],
        enterpriseName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        legalPerson: [{ required: true, message: '请输入法人', trigger: 'blur' }],
        creditCode: [{ required: true, message: '请输入信用代码', trigger: 'blur' }],
        registerCapital: [{ required: true, message: '请输入注册资本', trigger: 'blur' }],
        enterpriseNature: [{ required: true, message: '请选择企业性质', trigger: 'change' }],
        certificationTime: [{ required: true, message: '请选择成立时间', trigger: 'change' }],
        enterpriseSize: [{ required: true, message: '请选择企业规模', trigger: 'change' }],
        legalName: [{ required: true, message: '请输入公司联系人', trigger: 'blur' }],
        leaglTel: [{ required: true, message: '请输入公司联系方式', trigger: 'blur' },
         { pattern: /(^(\d{3,4}-)?\d{7,8})$|(^0?1[3456789]\d{9})$/, message: '请输入正确的公司联系方式', trigger: ['blur', 'change'] }],
        provinCode: [{ required: true, message: '请选择省级城市', trigger: 'change' }],
        cityCode: [{ required: true, message: '请选择市级城市', trigger: 'change' }],
        areaCode: [{ required: true, message: '请选择地区城市', trigger: 'change' }],
        address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
        registerUrl: [{ required: true, message: '请上传营业执照', trigger: 'change' }],
        enterpriseRemarke: [{ required: true, message: '请输入企业简介', trigger: 'blur' }],
      },
      value1: '',
      value: '',
    };
  },
  created(){
    this.getDictTypes('company_nature')
    this.getDictTypes('enterprise_size')
    this.getcityCode('',1)
    this.loadenterprise()
  },
  components: {
  },
  computed: {
    // 审核中禁止编辑
    disabledInput () {
      return this.params.examineStatus===1
    }
  },
  methods: {
    // 进度
    progress(event, file, fileList, type){
      if(parseInt(event.percent) === 100) {
        this.$set(this.percentage, type, 0)
      } else {
        this.$set(this.percentage, type, parseInt(event.percent))
      }

    },
    unEscapeHTML(html) {
        html= "" +html;
        return html.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&amp;/g, "&").replace(/&quot;/g, '"').replace(/&apos;/g, "'").replace(/<[^>]+>/g,"").replace(/&nbsp;/g,'');
    },
    // 获取
    loadenterprise(){
      getenterprise().then(res => {
        if(res.data){
          this.isEdite = true
          this.params = res.data
          if(this.params.enterpriseEnvironment){
            this.enterpriseEnvironment = this.params.enterpriseEnvironment.split(',')
          }
          if(this.params.enterpriseRemarke){
            this.params.enterpriseRemarke = this.unEscapeHTML(this.params.enterpriseRemarke)
          }
          this.getcityCode(this.params.provinCode, 2)
          this.getcityCode(this.params.cityCode, 3)
          this.$nextTick(() => {
            document.documentElement.scrollTop = 0
          })
        }
      })
    },
    // 获取下拉数据源
    getDictTypes(type){
      getDictType(type).then(res => {
        this.$set(this.options, type, res.data)
      })
    },
    selectChange(v, type,value){
      this.options[type].some(item => {
        if(item.dictCode === v) {
          this.params[value] = item.dictLabel
          return true
        }
      })
    },
    // 获取城市
    getcityCode(code, level){
      cityCode({
        code,
        level
      }).then(res => {
        this.$set(this.citys, level, res.data)
      })
    },
    changeUpload(v, type){
      this.params[type] = v.data.url
    },
    // 多选上传
    changeUpload2(v, type){
      if(this[type]){
        this[type].push(v.data.url)
      } else {
        this[type] = [v.data.url]
      }
    },
    beforeUpload(file){
      if(file.size > 10*1024*1024) {
        this.$message.warning('文件大小不能超过10M')
        return false
      }
    },
    // 删除列表
    removeImg(idx){
      this.enterpriseEnvironment.splice(idx, 1)
    },
    // 获取子集
    changecity(level){
      let code = ''
      if(level==2){
        this.params.cityCode = ''
        this.params.areaCode = ''
        code = this.params.provinCode
        this.citys[level-1].some(item => {
          if(code === item.dictCode){
            this.params.provinName = item.dictValue
            return true
          }
        })
        this.getcityCode(code, level)
      } else if(level==3){
        this.params.areaCode = ''
        code = this.params.cityCode
        this.citys[level-1].some(item => {
          if(code === item.dictCode){
            this.params.cityName = item.dictValue
            return true
          }
        })
        this.getcityCode(code, level)
      } else {
        this.citys[level-1].some(item => {
          if(this.params.areaCode === item.dictCode){
            this.params.areaName = item.dictValue
            return true
          }
        })
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(!this.isEdite) {
            enterpriseAdd({
              ...this.params,
              source: 1,
              enterpriseEnvironment: this.enterpriseEnvironment.join(',')
            }).then(() => {
              this.$message.success('添加成功！')
              this.loadenterprise()
              event.$emit('getInfoFn')
            })
          } else {
            enterpriseEdit({
              ...this.params,
              enterpriseEnvironment: this.enterpriseEnvironment.join(',')
            }).then(() => {
              this.$message.success('修改成功！')
              this.loadenterprise()
              event.$emit('getInfoFn')
            })
          }


        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  },
};
</script>
<style scoped lang="scss">
.zl {
  /deep/ .el-form-item{
    margin-bottom: 0!important;
  }
  .gzd {
    .el-form-item{
      display: inline-block;
    }
    .el-form-item:nth-child(3){
      margin-left: 12px;
      margin-right: 12px;
    }
  }
}
.center {
  overflow: hidden;
  .right {
    i{
      font-style: normal;
      color: red;
      margin-right: 3px;
    }
    .zt {
      background: #fff;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      margin-bottom: 20px;
      padding: 32px 16px;
      text-align: center;
      img {
        vertical-align: middle;
        margin-right: 5px;
      }
    }
    .zl {
      margin-right: -15px;
      background: #fff;
      overflow: hidden;
      padding: 16px;
    }
    .gs {
      span {
        float: left;
        margin-right: 16px;
        margin-top: 26px;
      }
      .custom-upload {
        /deep/ .el-upload--picture-card {
          width: 78px;
          height: 78px;
          border-radius: 0;
          .el-icon-zp {
            display: block;
            width: 78px;
            height: 78px;
            background: transparent
              url('../../assets/img/recruit/icon-logo.png') no-repeat left top;
          }
        }
      }
    }
    .qy-item {
      width: 306px;
      float: left;
      margin-top: 20px;
      margin-right: 15px;
      position: relative;
      .el-select,
      .el-date-editor {
        width: 100%;
      }
      .close{
        position: absolute;
        right: 0;
        top: 30px;
        color: red;
        background: #fff;
        cursor: pointer;
        z-index: 9;
        padding: 5px;
        font-size: 16px;
        &:hover{
          opacity: .8;
        }
      }
      &.gzd {
        .el-select {
          width: 94px;
          &:nth-child(3) {
            margin: 0 12px;
          }
        }
      }
      &.zwms{
        width: 950px;
      }
      p {
        margin-bottom: 8px;
        height: 19px;
      }
      .custom-upload2 {
        width: 100%;
        /deep/ .el-upload--picture-card {
          width: 100%;
          height: 170px;
          border: 0;
          border-radius: 0;
          background: #eeeeee url(../../assets/img/recruit/icon-sj.png)
            no-repeat center center;
        }
      }
    }
  }
}
.clear {
  clear: both;
}
.btn{
  background: #0780E6;
  border-radius: 0;
  padding: 9px 20px;
}
.avatar{
  background: #fff;
}
.qylogo{
  border-radius: 50%;
}
/deep/ .el-upload--picture-card{
  border: 0;
}
</style>
